<template>
    <div class="vue-credit-card" :style="getIsMobile ? 'max-height:1050px' : '' " :class="className">
        <div class="card-form-and-image" :style="{ flexDirection: direction }">
            <div class="credit-card-image" v-if="!noCard">
                <div class="creditcard" :class="{ flipped }" @click="flipped = !flipped">
                    <Front :cardNumber="form.cardNumber" :expiration="form.expiration" :name="form.name"
                        :isTwoDigitsYear="isTwoDigitsYear" :color="color">
                        <div slot="card-icon" id="ccsingle">
                            <component :is="cardInnerIcon" />
                        </div>
                    </Front>
                    <Back :nameBack="form.name" :security="form.security" :color="color" />
                </div>
            </div>
            <form id="cc_form">
                <div class="credit-card-form">
                    <div class="field">
                        <label for="name">{{ trans.name.label }}</label>
                        <input maxlength="40" name="name" id="name" type="text" :placeholder="trans.name.placeholder"
                            v-model="form.name" @focus="flipped = false" @blur="validation('')">
                        <span class="error" v-if="errors &&errors['name'] "> {{errors['name']}}</span>
                    </div>
                    <div class="field">
                        <label for="card-number">{{ trans.card.label }}</label>
                        <input type="text" name="card_number" id="card-number" ref="cardNumber" pattern="[0-9 ]*"
                            inputmode="numeric" :placeholder="trans.card.placeholder" @focus="flipped = false" @blur="validation('')">
                        <svg class="ccicon" width="750" height="471" viewBox="0 0 750 471" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <component :is="cardIcon" />
                        </svg>
                        <span class="error" v-if="errors &&errors['cardNumber'] "> {{errors['cardNumber']}}</span>   
                    </div>
                    <div class="field-group">
                        <div class="field">
                            <label for="expirationdate">{{ trans.expiration.label }} (mm/{{ isTwoDigitsYear ? 'yy' :'yyyy' }})</label>
                            <input type="text" name="expiration_date" id="expirationdate" pattern="[0-9\/]*"
                                ref="expiration" :placeholder="isTwoDigitsYear ? 'MM/YY' : 'MM/YYYY'"
                                inputmode="numeric" @focus="flipped = false" @blur="validation('')">
                            <span class="error" v-if="errors && (errors['expYear'] || errors['expMonth']) "> {{errors['expYear'] || errors['expYear'] ||errors['expMonth']}}</span>
                        </div>
                        <div class="field">
                            <label for="securitycode">{{ trans.security.label }}</label>
                            <input type="text" name="security_code" id="securitycode" ref="security" pattern="[0-9]*"
                                :placeholder="trans.security.placeholder" inputmode="numeric" @focus="flipped = true" @blur="validation('')">
                            <span class="error" v-if="errors &&errors['security'] "> {{errors['security']}}</span>
                        </div>
                        
                    </div>

                    <div class="field-group">
                        <div class="field">
                            <label for="Email">{{ trans.email.label }}</label>
                            <input maxlength="100" name="email" id="Email" type="text"
                                :placeholder="trans.email.placeholder" v-model="form.email" @blur="validation('')">
                                <span class="error" v-if="errors &&errors['email'] "> {{errors['email']}}</span>
                            
                        </div>
                        <div class="field" v-if="pgType!='FORTE'">
                            <label for="Address">{{ trans.address.label }}</label>
                            <input maxlength="100" name="address" id="Address" type="text"
                                :placeholder="trans.address.placeholder" v-model="form.address" @blur="validation('')">
                                <span class="error" v-if="errors &&errors['address'] " > {{errors['address']}}</span>
                        </div>
                    </div>
                    <div class="field-group">
                        <div class="field" v-if="pgType!='FORTE'">
                            <label for="City">{{ trans.city.label }}</label>
                            <input maxlength="20" name="city" id="City" type="text"
                                :placeholder="trans.city.placeholder" v-model="form.city" @blur="validation('')">
                                <span class="error" v-if="errors &&errors['city'] "> {{errors['city']}}</span>
                        </div>
                        <div class="field" v-if="pgType!='FORTE'">
                            <label for="State">{{ trans.state.label }}</label>
                            <select id="State" name="state" :placeholder="trans.state.placeholder" v-model="form.state" 
                                required>
                                <option value="">{{ trans.state.placeholder }}</option>
                                <option v-for="(state, index) in states" :key="index" :value="state.value">
                                    {{ state.text }}
                                </option>
                            </select>
                            <span class="error" v-if="errors &&errors['state'] "> {{errors['state']}}</span>
                        </div>
                    </div>
                    <div class="field-group">
                        <div class="field" v-if="pgType!='FORTE'">
                            <label for="Country">{{ trans.country.label }}</label>
                            <select id="Country" name="country" :placeholder="trans.country.placeholder"
                                v-model="form.country" @change="getStatesList"  required>
                                <option value="">Choose Country</option>
                                <option v-for="(country, index) in countriesList" :key="index" :value="country.code">
                                    {{ country.name }}
                                </option>
                            </select>
                            <span class="error" v-if="errors &&errors['country'] "> {{errors['country']}}</span>
                        </div>
                        <div class="field" v-if="pgType!='FORTE'">
                            <label for="ZipCode">{{ trans.zipCode.label }}</label>
                            <input maxlength="20" name="zipCode" id="ZipCode" type="text"
                                :placeholder="trans.zipCode.placeholder" v-model="form.zipCode" @blur="validation('')">
                                <span class="error" v-if="errors &&errors['zipCode'] "> {{errors['zipCode']}}</span>
                        </div>
                    </div>
                    <!-- <div class="field" v-if="errors && errors.length">
                        <span class="error"> Please validate your Information before proceed</span>
                    </div> -->
                    <div class="field">
                        <button type="button" @click="validation('clickButton')">
                            {{ buttonText }} {{ currency }} {{ amount }}
                        </button>
                    </div>

                </div>
            </form>
        </div>
        <div style="display: flex; align-items: center;">
            <img
              src="/assets/img/payments/sha.jpeg"
              alt="icon"
              class="icon"
              style="width: 120px; margin-right: 10px;"
            />
            <span>Safeguarded by SHA , ensuring the security of your sensitive information</span>
          </div>
    </div>
</template>

<script>
import IMask from 'imask';
import cardTypes from './cardTypes';
import { cardMasks, expirationMask, securityMask } from './masks';
import { Front, Back } from './cards';
import * as InputIcons from './icons';
import * as CardIcons from './singles';
import axios from 'axios';
import { mapGetters } from "vuex";
var statesList = require(`@/components/payment-link/states.json`);

const countries = [
    {
        code: 'US', name: 'United States'
    },
    {
        code: 'CA', name: 'Canada'
    },
    {
        code: 'IN', name: 'India'
    }
];

const formDefaults = {
    name: '',
    cardNumber: '',
    expiration: '',
    expMonth: '',
    expYear: '',
    security: '',
    cardType: '',
    email: '',
};

const addressDefaults={
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
}

const masksDefaults = {
    cardNumberMask: null,
    expirationDateMask: null,
    securityCodeMask: null
};

const defaultColor = 'grey';

const defaultTranslations = {
    name: {
        label: 'Name',
        placeholder: 'Full Name'
    },
    card: {
        label: 'Card Number',
        placeholder: 'Card Number'
    },
    cardNumber:{
         label: 'Card Number',
        placeholder: 'Card Number'
    },
    expiration: {
        label: 'Expiration'
    },
    security: {
        label: 'CVV',
        placeholder: 'CVV'
    },
    email: {
        label: 'Email',
        placeholder: 'Email'
    },
    address: {
        label: 'Address',
        placeholder: 'Address'
    },
    city: {
        label: 'City',
        placeholder: 'City'
    },
    state: {
        label: 'State',
        placeholder: 'State'
    },
    country: {
        label: 'Country',
        placeholder: 'Country'
    },
    zipCode: {
        label: 'Zip Code',
        placeholder: 'Zip Code'
    },
    expMonth:{
        label:'Expiration'
    },
    expYear:{
        label:'Expiration'
    }
}
const buttonContent = "Proceed to pay";
const buttonDefaultColor = "#04AA6D";
const defaultPgType="SWIREPAY";
export default {
    props: {
        trans: {
            type: Object,
            default: () => (defaultTranslations)
        },
        direction: {
            type: String,
            default: 'column',
            validator(value) {
                return ['row', 'column'].includes(value)
            }
        },
        className: {
            type: String,
            default: ''
        },
        noCard: {
            type: Boolean,
            default: false
        },
        yearDigits: {
            type: Number,
            default: 4,
            validator(value) {
                return [2, 4].includes(value)
            }
        },
        countriesList: {
            type: Array,
            default: () => (countries)
        },
        buttonText: {
            type: String,
            default: () => (buttonContent)
        },
        buttonColor: {
            type: String,
            default: () => (buttonDefaultColor)
        },
        customer:{
            type: Object,
        },
        amount:{
            type: String,
        },
        currency:{
            type: String
        },
        pgType:{
            type: String,
            default: () =>(defaultPgType)
        }
    },
    mounted() {
        this.defineMasks();
        this.setMasksListeners();
        this.mapCustomerInfo();
        this.getLocation();

        if(this.pgType!='FORTE')
            {
               this.form={...formDefaults,...addressDefaults}
            }
        
    },
    data() {
        return {
            flipped: false,
            cardType: null,
            cardIcon: null,
            cardInnerIcon: null,
            color: 'grey',
            masks: masksDefaults,
            form: formDefaults,
            states: [],
            errors: [],
        };
    },
    methods: {
        defineMasks() {
            //Mask the Credit Card Number Input
            this.cardNumberMask = new IMask(this.$refs.cardNumber, cardMasks);

            //Mask the Expiration Date
            this.expirationDateMask = new IMask(this.$refs.expiration, expirationMask(this.isTwoDigitsYear));

            //Mask the security code
            this.securityCodeMask = new IMask(this.$refs.security, securityMask);
        },
        setMasksListeners() {
            // Update expiration date field
            this.expirationDateMask.on('accept', () => {

                this.form.expiration = this.expirationDateMask.value
                if (this.form.expiration) {
                    let exp_mm_yyyy = this.form.expiration.split('/');
                    this.form.expMonth = exp_mm_yyyy && exp_mm_yyyy[0] ? parseInt(exp_mm_yyyy[0]) : '';
                    this.form.expYear = exp_mm_yyyy && exp_mm_yyyy[1] ? parseInt(exp_mm_yyyy[1]) : '';
                }
            });

            // Update security code field
            this.securityCodeMask.on('accept', () => this.form.security = this.securityCodeMask.value);

            // Update card number field and card icon
            this.cardNumberMask.on('accept', () => {
                const cardName = this.cardNumberMask.masked.currentMask.cardtype;
                // this.form.cardType = cardName=='undefined' || cardName;
                this.form.cardNumber = this.cardNumberMask.value;

                if (cardTypes && cardTypes[cardName]) {
                    const card = cardTypes[cardName];
                    this.cardIcon = InputIcons[card.name];
                    this.cardInnerIcon = CardIcons[card.name];
                    this.cardType = card.name;
                    this.form.cardType = card.name;
                    this.setColor(card.color);
                    return;
                }

                this.resetCardDefaults();
            });
        },
        resetCardDefaults() {
            this.cardIcon = null;
            this.cardInnerIcon = null;
            this.cardType = null;
            this.setColor();
        },
        setColor(colorName) {
            this.color = colorName || defaultColor;
        },
        async getLocation() {
            try {
                if(this.customer?.country)
                {
                    let states=this.getStates(this.customer.country)
                    if(states.length)
                    {
                        this.form.country = this.customer.country;
                        this.form.state = this.customer.state;
                        this.states = this.getStates(this.customer.country);
                        this.form.zipCode = this.customer.zip_code;
                    }
                    else{
                        console.log("Invalid country code");
                        throw "Invalid country code";
                    }
                    
                }else{
                    console.log("Invalid Not provided ")
                    throw "Invalid Not provided ";
                }
            }
            catch (err) {
                console.log(err)
                let data = await axios.get('https://ipapi.co/json/');
                console.log(data.data)
                this.form.zipCode = data.data.postal;
                this.form.country = data.data.country_code;
                this.states = this.getStates(data.data.country_code);
                this.form.state = data.data.region_code;
                this.form.city = data.data.city;
            }
        },
        async mapCustomerInfo()
        {
           this.form.name = this.customer?.name;
           this.form.email = this.customer?.email;
           if(this.pgType!='FORTE')
           {
           this.form.address = this.customer?.address;
           this.form.city = this.customer?.city;
           }
        },
        async getStatesList() {
            this.states = this.getStates(this.form.country);
            this.form.state = '';
        },
        validation(submit='') {
            this.errors = [];
            for (const key in this.form) {
                if (this.isEmpty(this.form[key])) {
                    this.errors.push({key:`${key} is required.`});
                    this.errors[key]=`${key} is required.`;
                    continue;
                }

                switch (key) {
                    case 'cardNumber':
                        if (!this.isValidCardNumber(this.form[key])) {
                            this.errors.push({key:`Invalid card number.`});
                            this.errors[key]=`Invalid card number.`;
                        }
                        break;
                    case 'expiration':
                        if (!this.isValidExpiration(this.form[key])) {
                            this.errors.push({key:`Invalid expiration date.`});
                        }
                        break;
                    case 'expMonth':
                        if (!this.isValidMonth(this.form[key])) {
                            this.errors.push({key:`Invalid expiration month.`});
                            this.error[key]= `Invalid expiration.`;
                        }
                        break;
                    case 'expYear':
                        if (!this.isValidYear(this.form[key])) {

                            this.errors.push({key:`Invalid expiration year.`});
                            this.errors[key]=`Invalid expiration.`;
                        }
                        break;
                    case 'security':
                        if (!this.isValidSecurity(this.form[key])) {
                            this.errors.push({key:`Invalid security code.`});
                            this.errors[key]=`Invalid security code.`;
                        }
                        break;
                    case 'email':
                        if (!this.isValidEmail(this.form[key])) {
                            this.errors.push({key:`Invalid email address.`});
                            this.errors[key]=`Invalid email address.`
                        }
                        break;
                    case 'name':
                    case 'zipCode':
                        // These fields just need to be non-empty
                        break;
                    default:
                    // this.errors.push( `No validation rule for ${key}.`);
                    console.log("No validation rule for "+key)

                    // case 'address':
                    // case 'city':
                    // case 'state':
                    // case 'country':
                }
            }
            if( this.errors && this.errors.length)
            {
                console.log(this.errors)
            }else{
                if(submit)
                {
                    console.log("submit Status",submit)
                    this.$emit('change', Object.assign({}, {...this.$data.form,type:0}));
                }
            }
        }
    },
    computed: {
        ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
        isTwoDigitsYear() {
            return this.yearDigits === 2;
        },
        getStates() {
            return (state) => {
                return statesList[state] ? statesList[state] : [];
            }
        },
        fields() {
            return [this.form.name, this.form.cardNumber, this.form.expiration, this.form.cardType, this.form.security].join('');
        },
        isEmpty() {
            return (value) => {
                return value?value === '':true;

            }
        },
        isValidCardNumber() {
            return (cardNumber) => {
                const cc = cardNumber.replaceAll(/\s/g,'');
                const regex = /^[0-9]{13,19}$/;
                if (!regex.test(cc)) return false;
                let sum = 0;
                let shouldDouble = false;
                for (let i = cc.length - 1; i >= 0; i--) {
                    let digit = parseInt(cc[i]);
                    if (shouldDouble) {
                        digit *= 2;
                        if (digit > 9) digit -= 9;
                    }
                    sum += digit;
                    shouldDouble = !shouldDouble;
                }
                return sum % 10 === 0;
            }
        },
        isValidExpiration() {
            return (expiration) => {
                const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2}|[0-9]{4})$/;
                return regex.test(expiration);
            }
        },

        isValidMonth() {
            return (month) => {
                const monthInt = parseInt(month);
                return monthInt >= 1 && monthInt <= 12;
            }
        },

        isValidYear() {
            return (year) => {
                const currentYear = new Date().getFullYear();
                const yearInt = parseInt(year.length === 2 ? '20' + year : year);
                return yearInt >= currentYear;
            }
        },

        isValidSecurity() {
            return (security) => {
                const regex = /^[0-9]{3,4}$/;
                return regex.test(security);
            }
        },

        isValidEmail() {
            return (email) => {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regex.test(email);
            }
        }
    },
    beforeDestroy() {
        this.form= formDefaults;
    },
    watch: {
        // fields() {
        //     this.$emit('change', Object.assign({}, this.$data.form));
        // },
        // cardType(val) {
        //     this.$emit('cardChanged', val);
        // }
    },
    components: {
        Front,
        Back
    }
}
</script>

<style lang="scss">
:root {
    --primary-color: #04AA6D;
    --secondary-color: #FFFFFF;
    --font-size: 16px;
}

.vue-credit-card {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .card-form-and-image {
        display: flex;
        align-items: center;
        justify-content: center;

        .credit-card-form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            //max-width: 400px;
            width: 100% !important;
            padding: 20px;
            color: #707070;

            .field-group {
                display: flex;

                .field:first-child {
                    margin-right: 10px;
                }
            }

            .field {
                position: relative;
                width: 100%;
                margin: 10px 0;

                label {
                    padding-bottom: 5px;
                    font-size: 13px;
                }
                .error{
                    color:#d32f2f;
                }

                select,
                input {
                    box-sizing: border-box;
                    margin-top: 3px;
                    padding: 15px;
                    font-size: 16px;
                    width: 100%;
                    border-radius: 3px;
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: var(--primary-color);
                    background-color: var(--secondary-color);
                    background-clip: padding-box;
                    border: 1px solid var(--primary-color);
                    border-radius: 0.25rem;
                    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                }

                select:focus-visible,
                input:focus-visible {
                    outline: var(--primary-color) auto 1px;
                }

                button {
                    padding: 10px 16px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                    margin: 4px 2px;
                    transition-duration: 0.4s;
                    cursor: pointer;
                    border-radius: 25px;
                    background-color: var(--secondary-color);
                    color: var(--primary-color);
                    font-weight: bold;
                    border: 2px solid var(--primary-color);
                    width: 100%;
                }

                button:hover {
                    background-color: var(--primary-color);
                    color: var(--secondary-color);
                }
            }


        }
    }

    .ccicon {
        height: 38px;
        position: absolute;
        right: 6px;
        top: calc(50% - 9px);
        width: 60px;
    }

    .credit-card-image {
        width: 100%;
        max-width: 400px;
        max-height: 251px;
        height: 54vw;
        padding: 20px;
        perspective: 1000px;
    }

    #ccsingle {
        position: absolute;
        right: 15px;
        top: 20px;

        svg {
            width: 100px;
            max-height: 60px;
        }
    }

    .creditcard {
        width: 100%;
        max-width: 400px;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        cursor: pointer;

        .front,
        .back {
            position: absolute;
            width: 100%;
            max-width: 400px;
            backface-visibility: hidden;
            color: #47525d;
        }

        .back {
            transform: rotateY(180deg);
        }

        &.flipped {
            transform: rotateY(180deg);
        }

        svg#cardfront,
        svg#cardback {
            width: 100%;
            box-shadow: 1px 5px 6px 0px black;
            border-radius: 22px;
        }

        .lightcolor,
        .darkcolor {
            transition: fill .5s;
        }

        .lightblue {
            fill: #03A9F4;
        }

        .lightbluedark {
            fill: #0288D1;
        }

        .red {
            fill: #ef5350;
        }

        .reddark {
            fill: #d32f2f;
        }

        .purple {
            fill: #ab47bc;
        }

        .purpledark {
            fill: #7b1fa2;
        }

        .cyan {
            fill: #26c6da;
        }

        .cyandark {
            fill: #0097a7;
        }

        .green {
            fill: #66bb6a;
        }

        .greendark {
            fill: #388e3c;
        }

        .lime {
            fill: #d4e157;
        }

        .limedark {
            fill: #afb42b;
        }

        .yellow {
            fill: #ffeb3b;
        }

        .yellowdark {
            fill: #f9a825;
        }

        .orange {
            fill: #ff9800;
        }

        .orangedark {
            fill: #ef6c00;
        }

        .grey {
            fill: #bdbdbd;
        }

        .greydark {
            fill: #616161;
        }
    }

    #svgname {
        text-transform: uppercase;
    }

    #cardfront {
        .st2 {
            fill: #FFFFFF;
        }

        .st3 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 600;
        }

        .st4 {
            font-size: 54.7817px;
        }

        .st5 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 400;
        }

        .st6 {
            font-size: 33.1112px;
        }

        .st7 {
            opacity: 0.6;
            fill: #FFFFFF;
        }

        .st8 {
            font-size: 24px;
        }

        .st9 {
            font-size: 36.5498px;
        }

        .st10 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 300;
        }

        .st11 {
            font-size: 16.1716px;
        }

        .st12 {
            fill: #4C4C4C;
        }
    }

    #cardback {
        .st0 {
            fill: none;
            stroke: #0F0F0F;
            stroke-miterlimit: 10;
        }

        .st2 {
            fill: #111111;
        }

        .st3 {
            fill: #F2F2F2;
        }

        .st4 {
            fill: #D8D2DB;
        }

        .st5 {
            fill: #C4C4C4;
        }

        .st6 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 400;
        }

        .st7 {
            font-size: 27px;
        }

        .st8 {
            opacity: 0.6;
        }

        .st9 {
            fill: #FFFFFF;
        }

        .st10 {
            font-size: 24px;
        }

        .st11 {
            fill: #EAEAEA;
        }

        .st12 {
            font-family: 'Rock Salt', cursive;
        }

        .st13 {
            font-size: 37.769px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .vue-credit-card .card-form-and-image .credit-card-form .field-group {
        display:block !important;
    }
    .vue-credit-card .card-form-and-image {
       margin-bottom: 180px !important;
    }
  }
</style>
